import { inventoryIcon, listIcon, scheduledIcon } from "assets/icons/svgIcons";

export default [
  {
    id: "assetAcceptance",
    title: "Asset Acceptance",
    icon: inventoryIcon,
    pageTitle: "Asset Acceptance List",
    navLink: "/asset-acceptance/list",
    type: "item",
    isActive: true,
    permissions: [
      "super_admin",
      "asset_supplier",
      "hospital_warehouse_manager",
      "engineer",
      "hospital_equipment_engineer",
      "clinical_division_head",
      "hospital_director",
      "cluster_equipment_engineer",
      "cluster_head",
      "asset_supplier",
      "hospital_warehouse_manager",
      "hospital_maintenance_biomedical_management",
      "hospital_biomedical_engineer",
      "clinical_division_head",
      "hospital_director",
      "cluster_equipment_biomedical_engineer",
      "cluster_head",
      "head_of_department",
    ],
  },
];